import { Component } from 'react';

import Header from '../common/Header';

class MainLayout extends Component<any, any> {
  render() {
    return (
      <div className="h-full w-full absolute">
        <Header />

        <div className="container my-10 mx-auto px-6 py-6 rounded-xl shadow-lg bg-base-300">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default MainLayout;
