import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { Button, Modal } from 'react-daisyui';
import { toast } from 'react-toastify';

import * as accountApi from '../../api/account.api';
import { WebhookType } from '../../dtos';

interface CreateModalProps {
  onClose: Function;
  onSubmit: Function;
  accountId: string;
}

const WebhookCreateModal: React.FC<CreateModalProps> = (props) => {
  const [url, setUrl] = useState('');
  const [type, setType] = useState(WebhookType.EVENTS);

  const submit = React.useCallback(async () => {
    try {
      await accountApi.webhookCreate(props.accountId, url, type);
      toast('Created webhook');
      props.onClose();
      props.onSubmit();
    } catch (error) {
      console.log('error', error);
    }
  }, [url, type, props]);

  return (
    <Modal open onClickBackdrop={() => props.onClose()}>
      <h3 className="text-lg font-bold">Create webhook</h3>

      <div className="text-center">
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Domain</span>
          </label>
          <input
            type="url"
            placeholder="https://example.com/webhook"
            className="input input-bordered rounded-lg w-full max-w-xs"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            required
          />
        </div>
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Type</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={type}
            onChange={(e) => setType(e.target.value as WebhookType)}>
            {(Object.keys(WebhookType) as (keyof typeof WebhookType)[]).map((key) => (
              <option value={WebhookType[key]} key={WebhookType[key]}>
                {capitalize(WebhookType[key])}
              </option>
            ))}
          </select>
        </div>
      </div>

      <Modal.Actions>
        <Button onClick={() => submit()} color="primary">
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WebhookCreateModal;
