import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { Button, Modal } from 'react-daisyui';
import { toast } from 'react-toastify';

import * as accountApi from '../../api/account.api';
import { AccountType, AccountWebhookOnly } from '../../dtos';

interface CreateModalProps {
  onClose: Function;
  onSubmit: Function;
}

const AccountCreateModal: React.FC<CreateModalProps> = (props) => {
  const [name, setName] = useState('');
  const [logo, setLogo] = useState('');
  const [type, setType] = useState(AccountType.PROVIDER);

  const submit = React.useCallback(async () => {
    try {
      await accountApi.create(name, logo, type);
      toast('Created account');
      props.onClose();
      props.onSubmit();
    } catch (error) {
      console.log('error', error);
    }
  }, [name, logo, type, props]);

  return (
    <Modal open onClickBackdrop={() => props.onClose()}>
      <h3 className="text-lg font-bold">Create account</h3>

      <div className="text-center">
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Name</span>
          </label>
          <input
            type="text"
            placeholder="Solrise Finance"
            className="input input-bordered rounded-lg w-full max-w-xs"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Logo</span>
          </label>
          <input
            type="url"
            placeholder="https://example.com/image.jpg"
            className="input input-bordered rounded-lg w-full max-w-xs"
            value={logo}
            onChange={(e) => setLogo(e.target.value)}
            required
          />
        </div>
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Type</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={type}
            onChange={(e) => setType(e.target.value as AccountType)}>
            {(Object.keys(AccountType) as (keyof typeof AccountType)[]).map((key) => (
              <option value={AccountType[key]} key={key}>
                {capitalize(AccountType[key])}
              </option>
            ))}
          </select>
        </div>
      </div>

      <Modal.Actions>
        <Button onClick={() => submit()} color="primary">
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AccountCreateModal;
