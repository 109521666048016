import { capitalize } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-daisyui';
import { useSearchParams } from 'react-router-dom';

import { AccountStatus, AccountType, OrderDir } from '../../dtos';

export interface AccountsListFilterData {
  type: AccountType | undefined;
  status: AccountStatus | undefined;
  domain: string | undefined;
  sortBy: AccountListSortByValues;
  sortDir: OrderDir;
}

export enum AccountListSortByLabels {
  CREATED_AT = 'Created at',
  KEYS_CONSUMER = "Consumers's key count",
  KEYS_PROVIDER = "Provider's key count"
}

export enum AccountListSortByValues {
  CREATED_AT = 'created_at',
  KEYS_CONSUMER = 'keys_consumer',
  KEYS_PROVIDER = 'keys_provider'
}

const AccountsListFilter: React.FC = () => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState<AccountType | undefined>(undefined);
  const [status, setStatus] = useState<AccountStatus | undefined>(undefined);
  const [domain, setDomain] = useState<string | undefined>(undefined);
  const [orderBy, setOrderBy] = useState(AccountListSortByValues.CREATED_AT);
  const [orderDir, setOrderDir] = useState(OrderDir.DESC);

  const [searchParams, setSearch] = useSearchParams();

  useEffect(() => {
    setType((searchParams.get('type') as AccountType) ?? undefined);
    setStatus((searchParams.get('status') as AccountStatus) ?? undefined);
    setDomain(searchParams.get('domain') ?? undefined);
    setOrderBy(
      (searchParams.get('orderBy') as AccountListSortByValues) ?? AccountListSortByValues.CREATED_AT
    );
    setOrderDir((searchParams.get('orderDir') as OrderDir) ?? OrderDir.DESC);
  }, [searchParams]);

  const search = useCallback(() => {
    setSearch({
      type: type ?? '',
      status: status ?? '',
      domain: domain ?? '',
      orderBy: orderBy,
      orderDir: orderDir
    });
  }, [type, status, domain, orderDir, orderBy]);

  if (!show) {
    return (
      <>
        <Button onClick={() => setShow(!show)}>Filter</Button>
      </>
    );
  }

  return (
    <>
      <div>
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Type</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={type ?? ''}
            onChange={(e) => setType(e.target.value as AccountType)}>
            <option value="">All</option>
            {(Object.keys(AccountType) as (keyof typeof AccountType)[]).map((key) => (
              <option value={AccountType[key]} key={key}>
                {capitalize(AccountType[key])}
              </option>
            ))}
          </select>
        </div>

        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Status</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={status ?? ''}
            onChange={(e) => setStatus(e.target.value as AccountStatus)}>
            <option value="">All</option>
            {(Object.keys(AccountStatus) as (keyof typeof AccountStatus)[]).map((key) => (
              <option value={AccountStatus[key]} key={key}>
                {capitalize(AccountStatus[key])}
              </option>
            ))}
          </select>
        </div>

        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Domain</span>
          </label>
          <input
            type="text"
            placeholder=""
            className="input input-bordered rounded-lg w-full max-w-xs"
            value={domain ?? ''}
            onChange={(e) => setDomain(e.target.value)}
            required
          />
        </div>

        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Sort By</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={orderBy ?? ''}
            onChange={(e) => setOrderBy(e.target.value as AccountListSortByValues)}>
            {(Object.keys(AccountListSortByValues) as (keyof typeof AccountListSortByValues)[]).map(
              (key) => (
                <option value={AccountListSortByValues[key]} key={key}>
                  {capitalize(AccountListSortByLabels[key])}
                </option>
              )
            )}
          </select>
        </div>

        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Sort Direction</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={orderDir ?? ''}
            onChange={(e) => setOrderDir(e.target.value as OrderDir)}>
            {(Object.keys(OrderDir) as (keyof typeof OrderDir)[]).map((key) => (
              <option value={OrderDir[key]} key={key}>
                {capitalize(OrderDir[key])}
              </option>
            ))}
          </select>
        </div>
      </div>

      <Button className="mr-10" color="success" onClick={() => search()}>
        Search
      </Button>
      <Button onClick={() => setShow(!show)}>Hide Filter</Button>
    </>
  );
};

export default AccountsListFilter;
