import { axiosInstance } from './axios';

export async function list(query: any) {
  const queryString = query ? `?${new URLSearchParams(query)}` : '';
  return await axiosInstance.get(`public-keys${queryString}`);
}

export async function removeProviderKey(id: string) {
  return await axiosInstance.delete(`public-keys/${id}/remove-provider`);
}
export async function removeConsumerKey(id: string) {
  return await axiosInstance.delete(`public-keys/${id}/remove-consumer`);
}
