import moment from 'moment/moment.js';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Pagination, Progress, Table } from 'react-daisyui';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as publicKeyApi from '../../api/publicKey.api';
import PublicKeysListFilter from '../../components/filters/PublicKeysListFilter';
import { AccountType, PublicKey } from '../../dtos';

const RenderItem: React.FC<{ item: PublicKey; removeKey: Function }> = (props) => {
  const item = props.item;

  return (
    <tr key={item.id}>
      <th className="flex flex-row items-center">
        {item.accountObject && (
          <img className="h-8 w-8 rounded-5 mr-2" src={item.accountObject.logo} alt="" />
        )}
        {!item.accountObject && <div className="h-8 w-8 rounded-5 mr-2" />}
        <div>
          <Link to={`/accounts/${item.accountObject?.id}`}>
            <div>{item.accountObject?.name} </div>
            <div className="badge badge-outline">{item.accountObject?.type}</div>
          </Link>
        </div>
      </th>
      <td>
        <div className="badge badge-outline">{item.keyType}</div>
      </td>
      <td>{item.publicKey}</td>
      <td>
        {item.connected !== false ? 'Yes' : 'No'}/{item.muted ? 'Yes' : 'No'}
      </td>
      <td>{item.readAt ? moment(item.readAt).utc().format('YYYY/MM/DD HH:mm:ss') : '/'}</td>
      <td>{moment(item.createdAt).utc().format('YYYY/MM/DD HH:mm:ss')}</td>
      <td>
        <Button
          color="error"
          size="xs"
          onClick={() => {
            if (confirm('Are you sure?')) {
              props.removeKey(item);
            }
          }}>
          Remove
        </Button>
      </td>
    </tr>
  );
};

const PublicKeysList: React.FC = () => {
  const [searchParams, setSearch] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const pageSearchParam = searchParams.get('page');

  const [data, setData] = useState<PublicKey[] | null>(null);
  const [page, setPage] = useState<number>(pageSearchParam !== null ? +pageSearchParam : 1);

  const fetch = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await publicKeyApi.list(Object.fromEntries(searchParams.entries()));
      setData(response.data.content);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  const removeKey = useCallback(
    async (item: PublicKey) => {
      try {
        if (item.keyType === AccountType.PROVIDER) {
          await publicKeyApi.removeProviderKey(item.id);
        } else {
          await publicKeyApi.removeConsumerKey(item.id);
        }
        toast('Public Key removed');
        fetch();
      } catch (e) {
        console.log(e);
      }
    },
    [fetch]
  );

  useEffect(() => {
    fetch().then();
  }, [fetch]);

  const pagination = () => {
    return (
      <Pagination>
        <Button
          disabled={page <= 1}
          onClick={() => {
            setPage(page - 1);
            searchParams.set('page', page - 1 + '');
            setSearch(searchParams);
          }}>
          Prev
        </Button>
        <Button
          disabled={data?.length === 0}
          onClick={() => {
            setPage(page + 1);
            searchParams.set('page', page + 1 + '');
            setSearch(searchParams);
          }}>
          Next
        </Button>
      </Pagination>
    );
  };

  return (
    <div>
      <PublicKeysListFilter />
      <div className="overflow-x-auto">
        <Table className="my-5 rounded-lg w-full" zebra>
          <thead>
            <tr>
              <th>Account</th>
              <th>Type</th>
              <th>Public Key</th>
              <th>Connected/Muted</th>
              <th>Read at (UTC)</th>
              <th>Created at (UTC)</th>
              <th />
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {data &&
                data.map((item: PublicKey, index) => (
                  <RenderItem key={index} item={item} removeKey={removeKey} />
                ))}
            </tbody>
          )}
        </Table>
        {loading && <Progress color="primary" />}
      </div>

      {pagination()}
    </div>
  );
};

export default PublicKeysList;
