import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment/moment.js';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Badge, Button, Divider, Modal, Progress, Table } from 'react-daisyui';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as accountApi from '../../api/account.api';
import AccountEditModal from '../../components/modals/AccountEditModal';
import AccountExternalCreateModal from '../../components/modals/AccountExternalCreateModal';
import AccountExternalRemoveModal from '../../components/modals/AccountExternalRemoveModal';
import DomainCreateModal from '../../components/modals/DomainCreateModal';
import WebhookCreateModal from '../../components/modals/WebhookCreateModal';
import { AccountModel, AccountType } from '../../dtos';
import { parseUser } from '../../utils/userObject';

interface Props {
  id: string | null;
}

const AccountsView: React.FC<Props> = (props) => {
  const { id } = useParams();

  const auth0 = useAuth0();
  const user = parseUser(auth0.user);

  const [data, setData] = useState<AccountModel | null>(null);
  const [editModal, setEditModal] = useState(false);
  const [apiKeyModal, setApiKeyModal] = useState(false);
  const [createDomainModal, setCreateDomainModal] = useState(false);
  const [createWebhookModal, setCreateWebhookModal] = useState(false);
  const [createAccountExternalModal, setCreateAccountExternalModal] = useState(false);
  const [removeAccountExternalModal, setRemoveAccountExternalModal] = useState(false);

  const getId = () => {
    return props.id ? props.id : (id as string);
  };

  const fetch = React.useCallback(async () => {
    try {
      const response = await accountApi.view(getId());
      setData(response.data.content);
    } catch (e) {
      console.error(e);
    }
  }, [id]);

  const removeDomain = React.useCallback(
    async (domainId: string) => {
      try {
        await accountApi.domainRemove(getId(), domainId);
        toast('Domain removed');
        fetch();
      } catch (e) {
        console.error(e);
      }
    },
    [id, fetch]
  );

  const removeWebhook = React.useCallback(
    async (webhookId: string) => {
      try {
        await accountApi.webhookRemove(getId(), webhookId);
        toast('Webhook removed');
        fetch();
      } catch (e) {
        console.error(e);
      }
    },
    [id, fetch]
  );

  useEffect(() => {
    fetch().then();
  }, [fetch]);

  if (!data) {
    return <Progress color="primary" />;
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="avatar">
            <div className="h-16 w-16">
              <img src={data.logo} alt="" />
            </div>
          </div>
          <div>
            <h1 className="text-lg font-extrabold">
              {data.name}
              <Badge className="ml-5">{data.type}</Badge>
            </h1>
            <div className="text-base-content/70 text-sm">#{data.id}</div>
          </div>
        </div>
        {user?.isAdmin() && (
          <div>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                setEditModal(true);
              }}>
              Edit
            </Button>
          </div>
        )}
      </div>

      <Divider />
      <div className="flow flex lg:flex-row flex-col w-full">
        <div className="w-full px-4">
          <div className="flex justify-between">
            <h2 className="font-bold text-lg">Domains</h2>
            {user?.isAdmin() && (
              <Button size="xs" color="success" onClick={() => setCreateDomainModal(true)}>
                Add domain
              </Button>
            )}
          </div>

          {data.providerDomains && data.providerDomains.length > 0 && (
            <Table className="w-full my-4" zebra compact>
              <thead>
                <tr>
                  <th />
                  {user?.isAdmin() && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {data.providerDomains?.map((domainObject, index) => (
                  <tr key={index}>
                    <th>{domainObject.domain}</th>
                    <td>
                      {user?.isAdmin() && (
                        <Button
                          size="xs"
                          color="error"
                          onClick={() => {
                            // eslint-disable-next-line no-restricted-globals
                            if (confirm('Are you sure')) {
                              removeDomain(domainObject.id);
                            }
                          }}>
                          Remove
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <Divider />

          <div className="flex justify-between">
            <h2 className="font-bold text-lg">Webhooks</h2>
            {user?.isAdmin() && (
              <Button size="xs" color="success" onClick={() => setCreateWebhookModal(true)}>
                Add webhook
              </Button>
            )}
          </div>

          {data.webhooks && data.webhooks.length > 0 && (
            <Table className="w-full my-4" zebra compact>
              <thead>
                <tr>
                  <th>URL</th>
                  <th>Type</th>
                  <th>Secret</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.webhooks?.map((webhook, index) => (
                  <tr key={index}>
                    <td>{webhook.url}</td>
                    <td>
                      <Badge>{webhook.type}</Badge>
                    </td>
                    <td className="flex items-center">
                      <div className="no-scrollbar overflow-y-hidden overflow-x-scroll w-24 bg-base-300 p-1 mr-2">
                        {webhook.secret}
                      </div>
                      <CopyToClipboard text={webhook.secret} onCopy={() => toast('Copied')}>
                        <Button size="xs">Copy</Button>
                      </CopyToClipboard>
                    </td>
                    <td>
                      <Button
                        size="xs"
                        color="error"
                        onClick={() => {
                          // eslint-disable-next-line no-restricted-globals
                          if (confirm('Are you sure')) {
                            removeWebhook(webhook.id);
                          }
                        }}>
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {user?.isAdmin() && data.type === AccountType.PROVIDER && (
            <div className="flex justify-between">
              <h2 className="font-bold text-lg">External Ids</h2>
              <div>
                <Button
                  size="xs"
                  color="success"
                  onClick={() => setCreateAccountExternalModal(true)}>
                  Add external ID
                </Button>{' '}
                <Button size="xs" color="error" onClick={() => setRemoveAccountExternalModal(true)}>
                  Remove external ID
                </Button>
              </div>
            </div>
          )}

          {user?.isAdmin() &&
            data.type === AccountType.PROVIDER &&
            data.accountExternals &&
            data.accountExternals.length > 0 && (
              <Table className="w-full my-4" zebra compact>
                <thead>
                  <tr>
                    <th>Source</th>
                    <th>DappIds</th>
                  </tr>
                </thead>
                <tbody>
                  {data.accountExternals?.map((accountExternal, index) => (
                    <tr key={index}>
                      <td>{accountExternal.externalSource}</td>
                      <td className="flex items-center">
                        <div className="no-scrollbar overflow-y-hidden overflow-x-scroll w-24 bg-base-300 p-1 mr-2">
                          {accountExternal.externalIds.map((id) => (
                            <span>{id}</span>
                          ))}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
        </div>

        <div className="lg:w-1/3 w-full px-4 text-sm">
          <div className="flex justify-between items-center p-1">
            <div className="w-24 font-bold">Type</div>
            <div>{data.type}</div>
          </div>

          <div className="flex justify-between items-center p-1">
            <div className="w-24 font-bold">Status</div>
            <div>{data.status}</div>
          </div>

          <div className="flex justify-between items-center p-1">
            <div className="w-24 font-bold">Opt Type</div>
            <div>{data.optType}</div>
          </div>

          {data.type === AccountType.CONSUMER && (
            <div className="flex justify-between items-center p-1">
              <div className="w-24 font-bold">Webhook only</div>
              <div>{data.webhookOnly ? 'True' : 'False'}</div>
            </div>
          )}

          <div className="flex justify-between items-center p-1">
            <div className="w-24 font-bold">Created</div>
            <div>{moment(data.createdAt).utc().format('YYYY/MM/DD HH:mm:ss')}</div>
          </div>

          <Divider />

          <h2 className="font-bold text-lg mb-2">Public Keys</h2>

          <div className="flex justify-between items-center p-1">
            <div className="w-24 font-bold">Provider keys</div>
            <Link to={`/public-keys/list?accountId=${data.id}&type=provider`}>
              <div>
                {data._count?.providerPublicKeys} ({data._count?.providerPublicKeysConnected})
              </div>
            </Link>
          </div>

          <div className="flex justify-between items-center p-1">
            <div className="w-24 font-bold">Consumer keys</div>
            <Link to={`/public-keys/list?accountId=${data.id}&type=consumer`}>
              <div>{data._count?.publicKeys}</div>
            </Link>
          </div>

          <Divider />

          <h2 className="font-bold text-lg mb-2">Casts</h2>

          <div className="flex justify-between items-center p-1">
            <div className="w-24 font-bold">Broadcasts</div>
            <Link to={`/casts/list?accountId=${data.id}&type=broadcast`}>
              <div>{data._count?.broadcast}</div>
            </Link>
          </div>

          <div className="flex justify-between items-center p-1">
            <div className="w-24 font-bold">Unicasts</div>
            <Link to={`/casts/list?accountId=${data.id}&type=unicast`}>
              <div>{data._count?.unicast}</div>
            </Link>
          </div>

          <Divider />

          <h2 className="font-bold text-lg mb-2">API Key</h2>

          <Button size="sm" onClick={() => setApiKeyModal(true)}>
            Show
          </Button>
        </div>
      </div>

      {apiKeyModal && (
        <Modal open onClickBackdrop={() => setApiKeyModal(false)}>
          <Modal.Header className="text-lg font-bold">API Key</Modal.Header>
          <Modal.Body>
            <div className="break-all p-2 bg-base-300">{data.apiKey}</div>
            <CopyToClipboard text={data.apiKey as string} onCopy={() => toast('Copied')}>
              <Button size="xs">Copy</Button>
            </CopyToClipboard>
          </Modal.Body>
        </Modal>
      )}

      {editModal && (
        <AccountEditModal
          onClose={() => setEditModal(false)}
          onSubmit={() => fetch()}
          account={data}
        />
      )}

      {createDomainModal && (
        <DomainCreateModal
          onClose={() => setCreateDomainModal(false)}
          onSubmit={() => fetch()}
          accountId={data.id}
        />
      )}

      {createWebhookModal && (
        <WebhookCreateModal
          onClose={() => setCreateWebhookModal(false)}
          onSubmit={() => fetch()}
          accountId={data.id}
        />
      )}

      {createAccountExternalModal && (
        <AccountExternalCreateModal
          onClose={() => setCreateAccountExternalModal(false)}
          onSubmit={() => fetch()}
          accountId={data.id}
        />
      )}
      {removeAccountExternalModal && (
        <AccountExternalRemoveModal
          onClose={() => setRemoveAccountExternalModal(false)}
          onSubmit={() => fetch()}
          accountId={data.id}
        />
      )}
    </>
  );
};

export default AccountsView;
