import { CastPlatform } from '../dtos';

import { axiosInstance } from './axios';

export async function list(query: any) {
  const queryString = query ? `?${new URLSearchParams(query)}` : '';
  return await axiosInstance.get(`casts${queryString}`);
}

export async function view(id: string) {
  return await axiosInstance.get(`casts/${id}`);
}

export async function remove(id: string) {
  return await axiosInstance.delete(`casts/${id}`);
}

export async function approve(id: string) {
  return await axiosInstance.post(`casts/${id}/approve`);
}

export async function decline(id: string, reason: string) {
  return await axiosInstance.post(`casts/${id}/decline`, { reason });
}

export async function send(body: {
  title: string;
  body: string;
  icon: string;
  image: string;
  actionUrl: string;
  topic: string;
  platform: CastPlatform;
  castAt: Date;
  publicKey: string;
  broadcast: boolean;
}) {
  return await axiosInstance.post('casts', body);
}
