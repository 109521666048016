import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { CastStatus } from '../dtos';
import { parseUser } from '../utils/userObject';

import AccountsView from './accounts/AccountsView';

const DashboardView: React.FC = () => {
  const auth0 = useAuth0();
  const user = parseUser(auth0.user);

  if (user?.isAdmin()) {
    return <Navigate to={`/casts/list?status=${CastStatus.PENDING}`} />;
  }

  return <AccountsView id={user?.getAccountId() as string} />;
};

export default DashboardView;
