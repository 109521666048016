import React from 'react';

interface Props {
  left: React.ReactElement | any;
  right: React.ReactElement | any;
}
const InfoRow: React.FC<Props> = (props) => {
  return (
    <div className="flex justify-between items-center p-1">
      <div className="w-24 font-bold">{props.left}</div>
      <div className="bg-base-100 p-2">{props.right}</div>
    </div>
  );
};

export default InfoRow;
