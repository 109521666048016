import React, { useState } from 'react';
import { Button, Modal } from 'react-daisyui';
import { toast } from 'react-toastify';

import * as accountApi from '../../api/account.api';

interface CreateModalProps {
  onClose: Function;
  onSubmit: Function;
  accountId: string;
}

const DomainCreateModal: React.FC<CreateModalProps> = (props) => {
  const [domain, setDomain] = useState('');

  const submit = React.useCallback(async () => {
    try {
      await accountApi.domainCreate(props.accountId, domain);
      toast('Created domain');
      props.onClose();
      props.onSubmit();
    } catch (error) {
      console.log('error', error);
    }
  }, [domain, props]);

  return (
    <Modal open onClickBackdrop={() => props.onClose()}>
      <h3 className="text-lg font-bold">Create domain</h3>

      <div className="text-center">
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Domain</span>
          </label>
          <input
            type="text"
            placeholder="example.com"
            className="input input-bordered rounded-lg w-full max-w-xs"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            required
          />
        </div>
      </div>

      <Modal.Actions>
        <Button onClick={() => submit()} color="primary">
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DomainCreateModal;
