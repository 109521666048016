import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { Progress } from 'react-daisyui';

import { genAxiosInstance } from '../api/axios';

interface Props {
  LoggedIn: React.ReactElement;
  LoggedOut: React.ReactElement;
}

const AuthProvider: React.FC<Props> = (props) => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin}>
      <AuthGuard {...props} />
    </Auth0Provider>
  );
};

const AuthGuard: React.FC<Props> = ({ LoggedIn, LoggedOut }) => {
  const { isAuthenticated, error, user, isLoading, getAccessTokenSilently } = useAuth0();

  const [token, setToken] = useState<string | null>(null);

  const getToken = React.useCallback(async () => {
    const _token = await getAccessTokenSilently();
    if (_token) {
      genAxiosInstance(_token);
      setToken(_token);
    }
  }, [getAccessTokenSilently]);

  React.useEffect(() => {
    if (isAuthenticated) {
      getToken();
    }
  });

  if (isLoading || (user && !token)) {
    return <Progress color="primary"></Progress>;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return isAuthenticated ? LoggedIn : LoggedOut;
};

export default AuthProvider;
