import { useAuth0 } from '@auth0/auth0-react';
import { capitalize } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-daisyui';
import { useSearchParams } from 'react-router-dom';

import * as accountApi from '../../api/account.api';
import { AccountModel, AccountStatus, AccountType } from '../../dtos';
import { parseUser } from '../../utils/userObject';

const PublicKeysListFilter: React.FC = () => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState<AccountType | undefined>(undefined);
  const [account, setAccount] = useState<string | undefined>(undefined);
  const [publicKey, setPublicKey] = useState<string | undefined>(undefined);
  const [accounts, setAccounts] = useState<AccountModel[]>([]);

  const [searchParams, setSearch] = useSearchParams();

  const auth0 = useAuth0();
  const user = parseUser(auth0.user);

  useEffect(() => {
    accountApi.listSimple().then((response) => {
      setAccounts(response.data.content);
    });
  }, []);

  useEffect(() => {
    setType((searchParams.get('type') as AccountType) ?? undefined);
    setAccount(searchParams.get('account') ?? undefined);
    setPublicKey(searchParams.get('publicKey') ?? undefined);
  }, [searchParams]);

  const search = useCallback(() => {
    setSearch({
      type: type ?? '',
      account: account ?? '',
      publicKey: publicKey ?? ''
    });
  }, [type, account, publicKey]);

  if (!show) {
    return (
      <>
        <Button onClick={() => setShow(!show)}>Filter</Button>
      </>
    );
  }

  return (
    <>
      <div>
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Type</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={type ?? ''}
            onChange={(e) => setType(e.target.value as AccountType)}>
            <option value="">All</option>
            {(Object.keys(AccountType) as (keyof typeof AccountType)[]).map((key) => (
              <option value={AccountType[key]} key={key}>
                {capitalize(AccountType[key])}
              </option>
            ))}
          </select>
        </div>

        {user?.isAdmin() && (
          <div className="form-control my-5">
            <label className="label">
              <span className="label-text">Account</span>
            </label>
            <select
              className="select select-bordered w-full max-w-xs"
              value={account ?? ''}
              onChange={(e) => setAccount(e.target.value as AccountStatus)}>
              <option value="">Any</option>
              {accounts.length &&
                accounts.map((account) => (
                  <option value={account.id} key={account.id}>
                    {capitalize(account.name)}
                  </option>
                ))}
            </select>
          </div>
        )}

        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Public Key</span>
          </label>
          <input
            type="text"
            placeholder=""
            className="input input-bordered rounded-lg w-full max-w-xs"
            value={publicKey ?? ''}
            onChange={(e) => setPublicKey(e.target.value)}
            required
          />
        </div>
      </div>

      <Button className="mr-10" color="success" onClick={() => search()}>
        Search
      </Button>
      <Button onClick={() => setShow(!show)}>Hide Filter</Button>
    </>
  );
};

export default PublicKeysListFilter;
