import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { Button, Modal } from 'react-daisyui';
import { toast } from 'react-toastify';

import * as accountApi from '../../api/account.api';
import { AccountExternalSource } from '../../dtos';

interface CreateModalProps {
  onClose: Function;
  onSubmit: Function;
  accountId: string;
}

const AccountExternalCreateModal: React.FC<CreateModalProps> = (props) => {
  const [source, setSource] = useState(AccountExternalSource.DIALECT);
  const [dappId, setDappId] = useState('');

  const submit = React.useCallback(async () => {
    try {
      await accountApi.externalIdCreate(props.accountId, source, dappId);
      toast('Created external Dapp ID');
      props.onClose();
      props.onSubmit();
    } catch (error) {
      console.log('error', error);
    }
  }, [dappId, source]);

  return (
    <Modal open onClickBackdrop={() => props.onClose()}>
      <h3 className="text-lg font-bold">Create external id</h3>

      <div className="text-center">
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">DappId</span>
          </label>
          <input
            type="url"
            className="input input-bordered rounded-lg w-full max-w-xs"
            value={dappId}
            onChange={(e) => setDappId(e.target.value)}
            required
          />
        </div>
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Source</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={source}
            onChange={(e) => setSource(e.target.value as AccountExternalSource)}>
            {(Object.keys(AccountExternalSource) as (keyof typeof AccountExternalSource)[]).map(
              (key) => (
                <option value={AccountExternalSource[key]} key={AccountExternalSource[key]}>
                  {capitalize(AccountExternalSource[key])}
                </option>
              )
            )}
          </select>
        </div>
      </div>

      <Modal.Actions>
        <Button onClick={() => submit()} color="primary">
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AccountExternalCreateModal;
