import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useState } from 'react';
import { Button, Drawer, Navbar } from 'react-daisyui';
import { Link } from 'react-router-dom';

import { AuthedUser, parseUser } from '../../utils/userObject';

const side = (user: AuthedUser | null, logout: Function, closeDrawer: Function) => {
  return (
    <ul className="menu p-4 overflow-y-auto w-80 bg-base-200">
      {renderHeaderLinks(user).map((elem, index) => {
        return (
          <li key={index} onClick={() => closeDrawer()}>
            {elem}
          </li>
        );
      })}

      {user && (
        <>
          <li>
            <div className="flex gap-0 flex-col items-start text-xs mr-5">
              <div>({user.getName()})</div>
              <div>{user.getEmail()}</div>
            </div>
          </li>

          <li className="text-right" onClick={() => logout()}>
            Logout
          </li>
        </>
      )}

      {!user && (
        <li>
          <Link onClick={() => closeDrawer()} to="/auth/login">
            Login
          </Link>
        </li>
      )}
    </ul>
  );
};

const renderHeaderLinks = (user: AuthedUser | null) => {
  if (!user) {
    return [];
  }

  if (!user.isAdmin()) {
    return [
      <Link to="/dashboard">Dashboard</Link>,
      <Link to="/casts/list">Casts</Link>,
      <Link to="/casts/compose">Compose</Link>,
      <Link to="/public-keys/list">Public Keys</Link>
    ];
  }

  return [
    <Link to="/dashboard">Dashboard</Link>,
    <Link to="/accounts/list">Accounts</Link>,
    <Link to="/casts/list">Casts</Link>,
    <Link to="/public-keys/list">Public Keys</Link>
  ];
};

export default function Header() {
  const useAuth = useAuth0();
  const user = parseUser(useAuth.user);

  const [drawer, setDrawer] = useState(false);

  const logout = useCallback(() => {
    useAuth.logout({ returnTo: window.location.origin });
  }, [useAuth]);

  return (
    <>
      <Navbar className="container mx-auto mt-2 shadow-lg bg-neutral text-neutral-content rounded-box">
        <Navbar.Start className="px-2 mx-2">
          <span className="text-lg font-bold">Solcast</span>
        </Navbar.Start>

        <Navbar.Center className="px-2 mx-2">
          <div className="hidden sm:flex items-stretch">
            {renderHeaderLinks(user).map((elem, index) => {
              return (
                <Button key={index} size="sm">
                  {elem}
                </Button>
              );
            })}
          </div>
        </Navbar.Center>

        <Navbar.End className="px-2 mx-2">
          <svg
            onClick={() => setDrawer(!drawer)}
            className="block sm:hidden mr-2 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 512 512">
            <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
          </svg>
          <div className="hidden sm:flex items-stretch">
            {user && (
              <>
                <div className="flex gap-0 flex-col items-center text-xs mr-5">
                  <div>({user.getName()})</div>
                  <div>{user.getEmail()}</div>
                </div>

                <Button onClick={() => logout()} size="sm">
                  Logout
                </Button>
              </>
            )}

            {!user && (
              <Button size="sm">
                <Link to="/auth/login">Login</Link>
              </Button>
            )}
          </div>
        </Navbar.End>
      </Navbar>
      {drawer && (
        <Drawer
          open
          id="drawer"
          side={side(user, logout, () => setDrawer(false))}
          className="absolute w-full h-screen z-50"></Drawer>
      )}
    </>
  );
}
