import { useAuth0 } from '@auth0/auth0-react';
import { capitalize } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-daisyui';
import { useSearchParams } from 'react-router-dom';

import * as accountApi from '../../api/account.api';
import { AccountModel, AccountStatus, CastStatus, CastType } from '../../dtos';
import { parseUser } from '../../utils/userObject';

const CastsListFilter: React.FC = () => {
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState<CastStatus | undefined>(undefined);
  const [type, setType] = useState<CastType | undefined>(undefined);
  const [account, setAccount] = useState<string | undefined>(undefined);
  const [accounts, setAccounts] = useState<AccountModel[]>([]);

  const [searchParams, setSearch] = useSearchParams();

  const auth0 = useAuth0();
  const user = parseUser(auth0.user);

  useEffect(() => {
    accountApi.listSimple().then((response) => {
      setAccounts(response.data.content);
    });
  }, []);

  useEffect(() => {
    setStatus((searchParams.get('status') as CastStatus) ?? undefined);
    setType((searchParams.get('type') as CastType) ?? undefined);
    setAccount(searchParams.get('account') ?? undefined);
  }, [searchParams]);

  const search = useCallback(() => {
    setSearch({
      status: status ?? '',
      account: account ?? '',
      type: type ?? ''
    });
  }, [status, account, type]);

  if (!show) {
    return (
      <>
        <Button onClick={() => setShow(!show)}>Filter</Button>
      </>
    );
  }

  return (
    <>
      <div>
        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Status</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={status ?? ''}
            onChange={(e) => setStatus(e.target.value as CastStatus)}>
            <option value="">Any</option>
            {(Object.keys(CastStatus) as (keyof typeof CastStatus)[]).map((key) => (
              <option value={CastStatus[key]} key={key}>
                {capitalize(CastStatus[key])}
              </option>
            ))}
          </select>
        </div>

        <div className="form-control my-5">
          <label className="label">
            <span className="label-text">Type</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={type ?? ''}
            onChange={(e) => setType(e.target.value as CastType)}>
            <option value="">Any</option>
            {(Object.keys(CastType) as (keyof typeof CastType)[]).map((key) => (
              <option value={CastType[key]} key={key}>
                {capitalize(CastType[key])}
              </option>
            ))}
          </select>
        </div>

        {user?.isAdmin() && (
          <div className="form-control my-5">
            <label className="label">
              <span className="label-text">Account</span>
            </label>
            <select
              className="select select-bordered w-full max-w-xs"
              value={account ?? ''}
              onChange={(e) => setAccount(e.target.value as AccountStatus)}>
              <option value="">Any</option>
              {accounts.length &&
                accounts.map((account) => (
                  <option value={account.id} key={account.id}>
                    {capitalize(account.name)}
                  </option>
                ))}
            </select>
          </div>
        )}
      </div>

      <Button className="mr-10" color="success" onClick={() => search()}>
        Search
      </Button>
      <Button onClick={() => setShow(!show)}>Hide Filter</Button>
    </>
  );
};

export default CastsListFilter;
