import axios, { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';

export let axiosInstance: AxiosInstance;

export function genAxiosInstance(token: string) {
  axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SOLCAST_PANEL_API + '/',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      }

      if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }

      return Promise.reject(error);
    }
  );
}
